import {
  type LeafInitializeUserOrganizationsOutput,
  type LeafMembershipsListOutput,
  type LeafUserProfileDetailOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'leaf';

const leafEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLeafUserProfile: build.query<LeafUserProfileDetailOutput, string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/user-profile/`,
      providesTags: [TagType.LeafUserProfile],
    }),
    getLeafMemberships: build.query<LeafMembershipsListOutput, string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/memberships/`,
      providesTags: [TagType.LeafMemberships],
    }),
    initializeLeafUserOrganizations: build.mutation<LeafInitializeUserOrganizationsOutput, void>({
      query: () => ({
        url: `/${RESOURCE}/initialize-user-organizations/`,
        method: 'POST',
      }),
    }),
    resyncLeafData: build.mutation<void, string>({
      query: (tid) => ({
        url: `/tid/${tid}/${RESOURCE}/resync-leaf-data/`,
        method: 'POST',
      }),
      invalidatesTags: [
        TagType.LeafUserProfile,
        TagType.LeafMemberships,
        TagType.ExternalMembership,
        TagType.Organization,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLeafUserProfileQuery,
  useGetLeafMembershipsQuery,
  useInitializeLeafUserOrganizationsMutation,
  useResyncLeafDataMutation,
} = leafEndpoints;
